
import React from "react"
import { Fade } from "react-awesome-reveal";
import Logo from "./logo"

const Footer = ({ siteTitle }) => {
  return (
    <footer className="footer">
      <Fade triggerOnce>
        <div className="container">
          <div className="row">
            <div className="col col-full">
              <Logo />
            </div>
            <div className="col col-one-third">
              <ul>
                <li><strong>Location</strong></li>
                <li>455 Princes Street,</li>
                <li>Dunedin Central, Dunedin 9016</li>
                <li>New Zealand</li>
              </ul>
              <ul>
                <li><strong>Phone</strong> 03 477 8250</li>
                <li><strong>Email</strong> enquiries@tudorclothing.co.nz</li>
              </ul>
            </div>
            <div className="col col-one-third">
              <ul>
                <li><strong>Monday</strong>	8am – 4:30pm</li>
                <li><strong>Tuesday</strong>	8am – 4:30pm</li>
                <li><strong>Wednesday</strong>	8am – 4:30pm</li>
                <li><strong>Thursday</strong>	8am – 4:30pm</li>
                <li><strong>Friday</strong>	8am – 2pm</li>
                <li><strong>Saturday</strong>	Closed</li>
                <li><strong>Sunday</strong>	Closed</li>
              </ul>
            </div>
            <div className="col col-one-third">
              <p><strong>Kiwi-Made Uniforms That Last</strong></p>
              <p>At Tudor Clothing, everything we make is sourced from local and quality and New Zealand-based materials. We are proud of our commitment to creating sustainable schoolwear for Dunedin Schools.</p>
            </div>
            <div className="col col-full">
              <p className="copyright">© {new Date().getFullYear()} {siteTitle}</p>
            </div>
          </div>
        </div>
      </Fade>
    </footer>
  )
}

export default Footer
