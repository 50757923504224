import React from "react"
import { Link } from "gatsby"

const Logo = () => (
  <Link className="logo-wrapper" to="/">
    <svg className="logo" xmlns="http://www.w3.org/2000/svg" width="600" height="285" viewBox="0 0 600 285">
      <path fill="#fff" d="M130.31,82H117.1l-1.46,1.47v68.84l4.4,13.18H89.23l4.4-13.18V83.43L92.17,82H79l-15.4,5.13,8.07-24.17h66l8.07,24.17Z"/>
      <path fill="#fff" d="M231.53,74.64v63l1.46,8.79-19.07,19H175.78l-19.07-19,1.47-8.79v-63l-2.94-11.72h28.61l-2.94,11.72v63l2.94,5.86,5.87,2.93h11l5.87-2.93,2.93-5.86v-63l-2.93-11.72h27.87Z"/>
      <path fill="#fff" d="M313.67,165.45H256.46l2.93-11.72V74.64l-2.93-11.72h57.21l20.54,20.51v61.51Zm-2.93-74-3.67-7.32-7.33-3.66H283.6L282.13,82v62.25l2.2,2.2h15.41l7.33-3.66,3.67-7.33Z"/>
      <path fill="#fff" d="M414.89,165.45H376.75l-19.07-19V82l19.07-19h38.14L434,82v64.45Zm-4.4-76.16-2.93-5.86-5.87-2.93H390l-5.87,2.93-2.93,5.86v48.33l2.93,5.86,5.87,2.93h11.74l5.87-2.93,2.93-5.86Z"/>
      <path fill="#fff" d="M510.24,165.45l-12.47-38.08-2.93-1.47h-11l-1.47,1.47v26.36l2.93,11.72H457.43l2.93-11.72V74.64l-2.93-11.72h60.15l17.6,17.58v24.9L519,121.51l11,30,8.06,13.91ZM511.71,90l-2.94-5.86-7.33-3.66h-17.6L482.37,82v24.9l1.47,1.47h17.6l7.33-3.66,2.94-5.86Z"/>
      <path fill="#fff" d="M524.89,23.17l51.9,53.06v75.58l-51.9,53.06H75.11l-51.9-53.06V76.23l51.9-53.06H524.89M534.67,0H65.33L0,66.79v94.46L65.33,228H534.67L600,161.25V66.79L534.67,0Z"/>
      <path fill="#fff" d="M71.93,285a17.63,17.63,0,0,1-4.31-.55,11.68,11.68,0,0,1-3.53-1.39l.43-3.51a14.25,14.25,0,0,0,3.52,1.5,13.49,13.49,0,0,0,3.73.57,6.6,6.6,0,0,0,3.74-.91,2.94,2.94,0,0,0,1.32-2.57,2.76,2.76,0,0,0-1.09-2.27,13.51,13.51,0,0,0-4.06-1.81,13.24,13.24,0,0,1-5.52-2.88,6.63,6.63,0,0,1-.65-7.85A6.44,6.44,0,0,1,68.37,261a10.56,10.56,0,0,1,4.38-.84,15.57,15.57,0,0,1,3.67.46,12.79,12.79,0,0,1,3.26,1.24l-.59,3.41A13.92,13.92,0,0,0,76,264a12,12,0,0,0-3.19-.47,5.87,5.87,0,0,0-3.36.81,2.72,2.72,0,0,0-1.16,2.35,2.8,2.8,0,0,0,.44,1.63,3.71,3.71,0,0,0,1.41,1.13,18.21,18.21,0,0,0,2.76,1.07,19.94,19.94,0,0,1,4.65,2A6.86,6.86,0,0,1,80,275a6.41,6.41,0,0,1,.74,3.13,6.26,6.26,0,0,1-1,3.59,6.9,6.9,0,0,1-3,2.41A11.91,11.91,0,0,1,71.93,285Z"/>
      <path fill="#fff" d="M99.51,284.84a13.79,13.79,0,0,1-6.44-1.47,11,11,0,0,1-4.49-4.23,12.21,12.21,0,0,1-1.65-6.39,12.76,12.76,0,0,1,1.58-6.35A11.6,11.6,0,0,1,93,262a13.78,13.78,0,0,1,6.77-1.63,15.48,15.48,0,0,1,3.94.53,13.5,13.5,0,0,1,3.43,1.41l-.66,3.39a20.12,20.12,0,0,0-3.56-1.38,12.47,12.47,0,0,0-3.25-.44,9.23,9.23,0,0,0-4.47,1.07A7.67,7.67,0,0,0,92.14,268,9.29,9.29,0,0,0,91,272.62a9.48,9.48,0,0,0,1,4.56,7.43,7.43,0,0,0,3,3.06,9.56,9.56,0,0,0,4.69,1.09,14.26,14.26,0,0,0,3.54-.47,18.09,18.09,0,0,0,3.61-1.31l.41,3.41A16.55,16.55,0,0,1,99.51,284.84Z"/>
      <path fill="#fff" d="M114.83,260.69h3.92v9.83h12.71v-9.83h3.92V284.5h-3.92V273.81H118.75V284.5h-3.92Z"/>
      <path fill="#fff" d="M155,285a12.78,12.78,0,0,1-6.22-1.55,11.85,11.85,0,0,1-4.52-4.4,13,13,0,0,1-.12-12.6,12.1,12.1,0,0,1,4.42-4.54,12.23,12.23,0,0,1,6.44-1.73,12.67,12.67,0,0,1,6.24,1.56,11.78,11.78,0,0,1,4.52,4.4,13,13,0,0,1,.13,12.59,12.18,12.18,0,0,1-4.44,4.55A12.31,12.31,0,0,1,155,285Zm0-3.51a8.23,8.23,0,0,0,4.22-1.1,8.08,8.08,0,0,0,3-3.13,9.49,9.49,0,0,0,1.13-4.67,9.67,9.67,0,0,0-1-4.49,8,8,0,0,0-3-3.22,8.57,8.57,0,0,0-8.59-.07,8,8,0,0,0-3,3.15,9.49,9.49,0,0,0-1.11,4.63,9.62,9.62,0,0,0,1,4.5,8,8,0,0,0,2.94,3.21A8.14,8.14,0,0,0,155,281.49Z"/>
      <path fill="#fff" d="M186.13,285a12.78,12.78,0,0,1-6.22-1.55,11.85,11.85,0,0,1-4.52-4.4,13,13,0,0,1-.12-12.6,12.1,12.1,0,0,1,4.42-4.54,12.23,12.23,0,0,1,6.44-1.73,12.67,12.67,0,0,1,6.24,1.56,11.78,11.78,0,0,1,4.52,4.4,13,13,0,0,1,.13,12.59,12.18,12.18,0,0,1-4.44,4.55A12.31,12.31,0,0,1,186.13,285Zm0-3.51a8.23,8.23,0,0,0,4.22-1.1,8.08,8.08,0,0,0,3-3.13,9.49,9.49,0,0,0,1.13-4.67,9.67,9.67,0,0,0-1-4.49,8,8,0,0,0-3-3.22,8.57,8.57,0,0,0-8.59-.07,8,8,0,0,0-3,3.15,9.49,9.49,0,0,0-1.11,4.63,9.62,9.62,0,0,0,1,4.5,7.94,7.94,0,0,0,3,3.21A8.1,8.1,0,0,0,186.13,281.49Z"/>
      <path fill="#fff" d="M205.77,260.69h3.92v20.36H220.9v3.45H205.77Z"/>
      <path fill="#fff" d="M223.25,260.69h4.08l5.21,19.11,5.62-18.8h3.7l5.62,18.64,5.18-18.95h4l-7.22,23.81h-3.92L239.92,266l-5.53,18.49h-3.95Z"/>
      <path fill="#fff" d="M263,260.69h15.28V264H266.78v6.73H277V274H266.78v7.21h12v3.29H263Z"/>
      <path fill="#fff" d="M293.77,260.69H298l9.57,23.81h-4.17l-2.51-6.68H290.69l-2.51,6.68h-4Zm5.89,14-3.92-10.4-3.89,10.4Z"/>
      <path fill="#fff" d="M313.85,260.69h7.34a9.19,9.19,0,0,1,6.23,2,6.74,6.74,0,0,1,2.31,5.4,6.93,6.93,0,0,1-1.13,3.86,7.1,7.1,0,0,1-3.11,2.6l6.62,10H327.5l-5.62-9.06a10.54,10.54,0,0,1-1.57.1h-2.69v9h-3.77Zm3.77,11.65h2.91q5.37,0,5.37-4.23a3.83,3.83,0,0,0-1.3-3.16,6,6,0,0,0-3.88-1.07h-3.1Z"/>
      <path fill="#fff" d="M350.63,279.93a4,4,0,1,1,4-4,4,4,0,0,1-4,4Z"/>
      <path fill="#fff" d="M371.12,260.69H386.4V264H374.89v6.73h10.26V274H374.89v7.21h12v3.29H371.12Z"/>
      <path fill="#fff" d="M401.34,285a17.63,17.63,0,0,1-4.31-.55,11.68,11.68,0,0,1-3.53-1.39l.43-3.51a14.25,14.25,0,0,0,3.52,1.5,13.49,13.49,0,0,0,3.73.57,6.6,6.6,0,0,0,3.74-.91,2.94,2.94,0,0,0,1.32-2.57,2.74,2.74,0,0,0-1.09-2.27,13.51,13.51,0,0,0-4.06-1.81,13.31,13.31,0,0,1-5.52-2.88,6.63,6.63,0,0,1-.65-7.85,6.44,6.44,0,0,1,2.86-2.31,10.56,10.56,0,0,1,4.38-.84,15.57,15.57,0,0,1,3.67.46,12.79,12.79,0,0,1,3.26,1.24l-.59,3.41a13.72,13.72,0,0,0-3.06-1.25,12,12,0,0,0-3.19-.47,5.87,5.87,0,0,0-3.36.81,2.72,2.72,0,0,0-1.16,2.35,2.8,2.8,0,0,0,.44,1.63,3.71,3.71,0,0,0,1.41,1.13,18,18,0,0,0,2.77,1.07,20,20,0,0,1,4.64,2,6.86,6.86,0,0,1,2.43,2.43,6.41,6.41,0,0,1,.74,3.13,6.26,6.26,0,0,1-1.05,3.59,6.9,6.9,0,0,1-3.05,2.41A11.88,11.88,0,0,1,401.34,285Z"/>
      <path fill="#fff" d="M423.15,264h-7.88v-3.29H435V264h-7.88V284.5h-3.92Z"/>
      <path fill="#fff" d="M457.26,265l-6.21,2.79-.57-3.23,7.57-3.38h2.88V284.5h-3.67Z"/>
      <path fill="#fff" d="M474.33,284.4q6.18-5.19,8.45-10.24a5.62,5.62,0,0,1-1.89,1.13,7.23,7.23,0,0,1-2.35.34,7,7,0,0,1-3.52-.91,6.89,6.89,0,0,1-2.57-2.57,7.26,7.26,0,0,1-1-3.76,7.71,7.71,0,0,1,1-3.91,7.22,7.22,0,0,1,2.86-2.78,8.78,8.78,0,0,1,4.27-1,7.78,7.78,0,0,1,5.77,2.17,7.91,7.91,0,0,1,2.16,5.82,15,15,0,0,1-1.05,5.24,24.32,24.32,0,0,1-3,5.58,27.7,27.7,0,0,1-4.64,5.06Zm5.21-11.68a4.49,4.49,0,0,0,2.26-.57,4.08,4.08,0,0,0,1.6-1.59,4.62,4.62,0,0,0,.6-2.35,4.35,4.35,0,0,0-1.26-3.21,4.66,4.66,0,0,0-6.37,0,4.38,4.38,0,0,0-1.25,3.23,4.47,4.47,0,0,0,1.22,3.25A4.26,4.26,0,0,0,479.54,272.72Z"/>
      <path fill="#fff" d="M494.17,284.5v-2.95a76.45,76.45,0,0,0,5.93-6,32.71,32.71,0,0,0,3.47-4.7,6.93,6.93,0,0,0,.92-3.29,3.54,3.54,0,0,0-1-2.67,3.84,3.84,0,0,0-2.76-1,6.86,6.86,0,0,0-3.05.72,7.89,7.89,0,0,0-2.6,2.07l-.69-3.23a8.61,8.61,0,0,1,3-2,10.12,10.12,0,0,1,3.83-.73,7.33,7.33,0,0,1,5.11,1.72,5.92,5.92,0,0,1,1.92,4.64,9.94,9.94,0,0,1-1.19,4.56,26,26,0,0,1-3.86,5.21q-2.52,2.7-4.3,4.36h10v3.32Z"/>
      <path fill="#fff" d="M523.6,285a7.09,7.09,0,0,1-6.15-3.23c-1.48-2.15-2.23-5.13-2.23-8.93s.75-6.77,2.23-8.93a7.48,7.48,0,0,1,12.3,0c1.49,2.16,2.23,5.13,2.23,8.93s-.74,6.78-2.23,8.93A7.07,7.07,0,0,1,523.6,285Zm0-3.29a3.58,3.58,0,0,0,3.33-2.27,19.81,19.81,0,0,0,0-13.19,3.57,3.57,0,0,0-6.65,0,19.81,19.81,0,0,0,0,13.19A3.58,3.58,0,0,0,523.6,281.71Z"/>
    </svg>
  </Link>
)

export default Logo
