import React, { useState } from "react"

import { Link } from "gatsby"
import Logo from "./logo"

const Header = ({ navSolid }) => {

  const [toggleMenu, setToggleMenu] = useState(false);
  const menuToggle = () => {
    setToggleMenu(!toggleMenu);
  };

  const [toggleSchoolsDropdown, setToggleSchoolsDropdown] = useState(false);
  const schoolsToggle = () => {
    setToggleSchoolsDropdown(!toggleSchoolsDropdown);
    setToggleAboutDropdown(false);
  };

  const [toggleAboutDropdown, setToggleAboutDropdown] = useState(false);
  const aboutToggle = () => {
    setToggleAboutDropdown(!toggleAboutDropdown);
    setToggleSchoolsDropdown(false);
  };

  return (
    <React.Fragment>
      <header className={navSolid ? "header header-solid" : "header"}>
        <div className="header-content">
          <div className="nav-left">
            <Logo />
            <div className="nav">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li>
                  <div className="dropdown-wrapper">
                    <Link to="/about-us/">
                      About us <svg xmlns="http://www.w3.org/2000/svg" className="dropdown-icon" width="16" height="16" viewBox="0 0 16 16"><polygon fill="#000000" points="8,11.4 2.6,6 4,4.6 8,8.6 12,4.6 13.4,6 "></polygon></svg>
                    </Link>
                    <div className="nav-dropdown">
                      <ul>
                        <li><Link to="/about-us/ethos/">Our Ethos</Link></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="dropdown-wrapper">
                    <Link to="/schools/">
                      Schools <svg xmlns="http://www.w3.org/2000/svg" className="dropdown-icon" width="16" height="16" viewBox="0 0 16 16"><polygon fill="#000000" points="8,11.4 2.6,6 4,4.6 8,8.6 12,4.6 13.4,6 "></polygon></svg>
                    </Link>
                    <div className="nav-dropdown">
                      <ul>
                        <li><Link to="/schools/balmacewen-intermediate/">Balmacewen Intermediate</Link></li>
                        <li><Link to="/schools/columba-college/">Columba College</Link></li>
                        <li><Link to="/schools/john-mcglashan-college/">John McGlashan College</Link></li>
                        <li><Link to="/schools/kaikorai-valley-college/">Kaikorai Valley College</Link></li>
                        <li><Link to="/schools/otago-boys-high-school/">Otago Boys' High School</Link></li>
                        <li><Link to="/schools/otago-girls-high-school/">Otago Girls' High School</Link></li>
                        <li><Link to="/schools/queens-high-school/">Queen's High School</Link></li>
                        <li><Link to="/schools/st-hildas-collegiate/">St Hilda's Collegiate</Link></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="nav">
            <ul>
              <li>
                <a href="https://www.facebook.com/tudorschoolwear/" target="_blank" rel="noreferrer">
                  <svg className="social-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M9.03153,23L9,13H5V9h4V6.5C9,2.7886,11.29832,1,14.60914,1c1.58592,0,2.94893,0.11807,3.34615,0.17085 v3.87863l-2.29623,0.00104c-1.80061,0-2.14925,0.85562-2.14925,2.11119V9H18.75l-2,4h-3.24019v10H9.03153z"></path></svg> Facebook
                </a>
              </li>
              <li>
                <Link to="/contact-us/" className="button button-inverted">
                  Contact us <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon fill="#ffec81" points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
                </Link>
              </li>
            </ul>
          </div>
          <button className="hamburger-button" onClick={menuToggle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <path fill="#fff" d="M30,15H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,15,30,15z"></path>
              <path fill="#fff" d="M30,6H2C1.4,6,1,6.4,1,7s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,6,30,6z"></path>
              <path fill="#fff" d="M30,24H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,24,30,24z"></path>
            </svg>
          </button>
        </div>
      </header>
      <div className={toggleMenu ? 'mobile-nav open' : 'mobile-nav'}>
        <div className="mobile-nav-header">
          <Logo />
          <button className="hamburger-button" onClick={menuToggle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <line strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="#000000" strokeMiterlimit="10" x1="19" y1="5" x2="5" y2="19"></line>
              <line strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="#000000" strokeMiterlimit="10" x1="19" y1="19" x2="5" y2="5"></line>
            </svg>
          </button>
        </div>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li>
            <div className={toggleAboutDropdown ? 'dropdown-wrapper expanded' : 'dropdown-wrapper'}>
              <div className="dropdown-parent">
                <Link to="/about-us/">
                  About us
                </Link>
                <button className="nav-expand" onClick={aboutToggle}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#000000" d="M15,7H9V1c0-0.6-0.4-1-1-1S7,0.4,7,1v6H1C0.4,7,0,7.4,0,8s0.4,1,1,1h6v6c0,0.6,0.4,1,1,1s1-0.4,1-1V9h6 c0.6,0,1-0.4,1-1S15.6,7,15,7z"></path></svg>
                </button>
              </div>
              <div className="nav-dropdown">
                <ul>
                  <li><Link to="/about-us/ethos/">Ethos</Link></li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className={toggleSchoolsDropdown ? 'dropdown-wrapper expanded' : 'dropdown-wrapper'}>
              <div className="dropdown-parent">
                <Link to="/schools/">
                  Schools
                </Link>
                <button className="nav-expand" onClick={schoolsToggle}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#000000" d="M15,7H9V1c0-0.6-0.4-1-1-1S7,0.4,7,1v6H1C0.4,7,0,7.4,0,8s0.4,1,1,1h6v6c0,0.6,0.4,1,1,1s1-0.4,1-1V9h6 c0.6,0,1-0.4,1-1S15.6,7,15,7z"></path></svg>
                </button>
              </div>
              <div className="nav-dropdown">
                <ul>
                  <li><Link to="/schools/balmacewen-intermediate/">Balmacewen Intermediate</Link></li>
                  <li><Link to="/schools/columba-college/">Columba College</Link></li>
                  <li><Link to="/schools/john-mcglashan-college/">John McGlashan College</Link></li>
                  <li><Link to="/schools/kaikorai-valley-college/">Kaikorai Valley College</Link></li>
                  <li><Link to="/schools/otago-boys-high-school/">Otago Boys' High School</Link></li>
                  <li><Link to="/schools/otago-girls-high-school/">Otago Girls' High School</Link></li>
                  <li><Link to="/schools/queens-high-school/">Queen's High School</Link></li>
                  <li><Link to="/schools/st-hildas-collegiate/">St Hilda's Collegiate</Link></li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
        <div className="mobile-nav-footer">
          <a href="https://www.facebook.com/tudorschoolwear/" target="_blank" rel="noreferrer">
            <svg className="social-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M9.03153,23L9,13H5V9h4V6.5C9,2.7886,11.29832,1,14.60914,1c1.58592,0,2.94893,0.11807,3.34615,0.17085 v3.87863l-2.29623,0.00104c-1.80061,0-2.14925,0.85562-2.14925,2.11119V9H18.75l-2,4h-3.24019v10H9.03153z"></path></svg>
          </a>
          <Link to="/contact-us/" className="button">
            Contact us <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon fill="#ffec81" points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Header
